import styled from 'styled-components';

export const StyledNavigation = styled.ul`
    list-style: none;
    width: 20px;
    position: fixed;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    li {
        display: block;
        width: 15px;
        height: 15px;
        border: 1px solid #ffffff;
        border-radius: 100%;
        margin-bottom: 10px;
        cursor: pointer;
        transition: all 0.3s;
        &:hover,
        &.active {
            background: #ffffff;
        }
    }
`;
