import styled from 'styled-components';

export const StyledExperience = styled.div`
    width: 100%;
    height: 2px;
    background: #1c1c1c;

    .size {
        height: 3px;
        width: 0;
    }
`;
