import React from 'react';
import { Menu } from '../Menu';
import { StyledHeader } from './styles';

export const Header = () => {
    return (
        <StyledHeader>
            <Menu />
        </StyledHeader>
    );
};
