import styled from 'styled-components';

export const StyledMenu = styled.ul`
    list-style: none;
    color: #fff;
    text-align: end;
    padding: 0;

    li {
        display: inline-block;
        margin-left: 20px;

        span {
            cursor: pointer;
        }
    }

    span {
        color: #fff;
        position: relative;
        text-decoration: none;
        transition: all 0.3s;
        @media (max-width: 640px) {
            font-size: 16px;
        }

        &:after {
            content: '';
            position: absolute;
            bottom: -4px;
            width: 0;
            height: 2px;
            background: #fd2055;
            transition: all 0.3s;
            left: 50%;
            transform: translateX(-50%);
        }

        &.active {
            color: #fd2055;

            &:after {
                width: 100%;
            }
        }

        &:hover {
            color: #fd2055;

            &:after {
                width: 100%;
            }
        }
    }
`;
