import React from 'react';
import { Layout } from '../../components/Layout';
import { StyledBio } from './styles';
import { Button } from '../../components/Button';
import { useRouter } from '../../components/SliderRouter/hooks/useRouter';

export const Home = () => {
    const { navigate } = useRouter();
    return (
        <Layout showBackground>
            <StyledBio>
                <div className="user" />
                <div className="bio">
                    <ul>
                        <li>
                            Hi, I'm <span className="br">R</span>ussy, web developer based in
                            Ukraine! <span className="flag" />
                        </li>
                    </ul>

                    <div className="description">
                        <p>
                            I'm an accomplished full stack developer with a solid background in
                            Information Technology Design. I have a proven track record of creating
                            dynamic and engaging web applications using cutting-edge technologies,
                            and I am passionate about staying up-to-date with the latest industry
                            advancements.
                        </p>
                        <p>
                            I pride myself on being an effective problem solver, often going above
                            and beyond to find solutions even outside of regular working hours.
                        </p>
                        <p>
                            I've had the pleasure of working on, including custom WordPress
                            themes/plugins, mobile-first web applications, and robust backends using
                            Laravel and Codeigniter. I've also had the opportunity to work with
                            Angular and React, further broadening my skill set and allowing me to
                            adapt to a variety of project requirements.
                        </p>
                    </div>
                    <Button
                        className="contact-me"
                        onClick={() => {
                            navigate('/contacts');
                        }}
                    >
                        Contact Me!
                    </Button>
                </div>
            </StyledBio>
        </Layout>
    );
};
