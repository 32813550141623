import React from 'react';
import { StyledCompany } from './styles';
import { TCompany } from '../../types';

type TProps = {
    className?: string;
} & TCompany;

export const Company = ({ position, company, logo, period, responsibility, className }: TProps) => {
    return (
        <StyledCompany className={className}>
            <div className="position">{position}</div>
            <div className="company-name">
                <img src={logo} alt="" />
                <span>{company}</span>
            </div>
            <div className="period">{period}</div>
            <div className="responsibility">{responsibility}</div>
        </StyledCompany>
    );
};
