import styled from 'styled-components';

export const StyledCompany = styled.div`
    padding: 20px;
    background: rgba(255, 255, 255, 0.1);
    display: inline-block;

    // box-shadow: 2px 2px 9px -1px #484848;

    .position {
        font-size: 30px;
        margin-bottom: 20px;
    }

    .company-name {
        font-size: 18px;
        color: #a9a9a9;
        display: inline-flex;
        align-items: center;
        margin-bottom: 10px;
        width: 100%;
        img {
            display: inline-block;
            width: 40px;
            margin-right: 20px;
        }
    }

    .period {
        font-size: 14px;
        color: #a9a9a9;
        margin-bottom: 20px;
    }

    .responsibility {
        font-size: 18px;
        color: #a9a9a9;
        p {
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.5px;
        }
    }
`;
