import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Layout } from '../../components/Layout';
import { Title } from '../../components/Title';
import { StyledSkills } from './styles';
import { Experience } from './components/Experience';

const skills = [
    { title: 'Frontend', size: '95%', color: '#09fcd8' },
    { title: 'VanillaJs', size: '95%', color: '#e6d31a' },
    { title: 'React', size: '90%', color: '#04d1f2' },
    { title: 'Angular', size: '60%', color: '#dd1b16' },
    { title: 'Backend', size: '85%', color: '#038ff0' },
    { title: 'Wordpress', size: '85%', color: '#08a0cc' },
    { title: 'Laravel', size: '60%', color: '#e3372b' },
];

const tags = [
    'Javascript',
    'PHP',
    'ReactJs',
    'VanillaJs',
    'Wordpress',
    'Laravel',
    'Angular',
    'VueJs',
    'MySql',
    'MongoDB',
    'DynamoDB',
    'Codeigniter',
    'Kohana',
    'Python',
    'jQuery',
    'HTML',
    'css',
    'SCSS',
    'Less',
    'BEM',
    'Linux',
    'Photoshop',
    'Apache',
    'Networking',
    'Backend',
    'Frontend',
    'NoSql',
    'Stripe',
    'Prestashop',
    'Git',
    'SVN',
    'Flex',
    'Grid',
    'Mobile First',
    'Responsive',
];

function shuffle<T>(array: T[]): T[] {
    let currentIndex = array.length;
    let randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex !== 0) {
        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        // eslint-disable-next-line no-param-reassign
        [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }

    return array;
}

export const Skills = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setIsVisible(true);
        }, 100);
        return () => {
            setIsVisible(false);
        };
    }, []);

    return (
        <Layout>
            <StyledSkills>
                <Title className={classNames({ visible: isVisible })}>My Skills!</Title>
                <div className="content">
                    <div className="skills">
                        {skills.map((skill) => {
                            return (
                                <div key={skill.color} className="skill">
                                    <span>{skill.title}</span>
                                    <Experience size={skill.size} color={skill.color} />
                                </div>
                            );
                        })}
                    </div>
                    <div className="tags">
                        {shuffle(tags).map((tag, key) => {
                            return (
                                <span
                                    key={key}
                                    style={{ margin: `5px ${Math.random() * (15 - 2) + 2}px` }}
                                >
                                    {tag}
                                </span>
                            );
                        })}
                    </div>
                </div>
            </StyledSkills>
        </Layout>
    );
};
