import styled from 'styled-components';

export const StyledButton = styled.div`
    display: inline-block;
    padding: 10px 20px;
    border-radius: 4px;
    border: 2px solid #09fcd8;
    cursor: pointer;
    color: #09fcd8;
    transition: all 0.5s;
    &:hover {
        color: #fd2055;
        border-color: #fd2055;
        // background: #fd2055;
    }
`;
