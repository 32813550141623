import styled from 'styled-components';

export const StyledHeader = styled.div`
    position: fixed;
    z-index: 10;
    padding: 0 10px;
    top: 0;
    left: 0;
    width: 100%;
    color: #ccc;
    background: #000;
`;
