import React from 'react';
import { StyledButton } from './styles';

type TProps = {
    children: React.ReactNode;
    onClick: () => void;
    className?: string;
};
export const Button = ({ children, onClick, className }: TProps) => {
    return (
        <StyledButton className={className} onClick={onClick}>
            {children}
        </StyledButton>
    );
};
