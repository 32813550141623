import styled from 'styled-components';
import bgImg from '../../assets/img/bg3.jpg';

export const StyledLayout = styled.div`
    position: relative;
    z-index: 1;
    padding: 0 10vw;
    min-height: 100vh;
    &.withBg {
        ${() => `background: url(${bgImg});`}
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
    .content {
        position: relative;
        z-index: 2;
        padding-top: 70px;
    }
    @media (max-width: 640px) {
        padding: 0 20px;
    }
`;
