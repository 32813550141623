import React from 'react';
import classNames from 'classnames';
import { StyledNavigation } from './styles';

type TProps = {
    count: number;
    active: number;
    onClick: (n: number) => void;
};

export const Navigations = ({ count, active, onClick }: TProps) => {
    return (
        <StyledNavigation>
            {new Array(count).fill(1).map((index, key) => {
                return (
                    <li
                        key={key}
                        className={classNames({ active: key === active })}
                        onClick={() => {
                            onClick(key);
                        }}
                    />
                );
            })}
        </StyledNavigation>
    );
};
