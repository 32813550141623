import React from 'react';
import { StyledInfo } from './styles';
import linkedin from '../../../../assets/svg/linkedin.svg';
import facebook from '../../../../assets/svg/facebook.svg';
import gmail from '../../../../assets/svg/gmail.svg';

const links = [
    {
        title: 'LinkedIn',
        url: 'https://www.linkedin.com/in/ruslan-kolibabchuk-b3341372/?originalSubdomain=ua',
        icon: linkedin,
    },
    {
        title: 'Facebook',
        url: 'https://www.facebook.com/ruslan.kolibabcuk',
        icon: facebook,
    },
    {
        title: 'Email',
        url: 'mailto:ruslan.kolibabchuk@gmail.com',
        icon: gmail,
    },
];

export const Info = () => {
    return (
        <StyledInfo>
            <ul>
                {links.map((link, key) => {
                    return (
                        <li key={key}>
                            <a href={link.url} target="__blank">
                                <img src={link.icon} alt={link.title} />
                                <span>{link.title}</span>
                            </a>
                        </li>
                    );
                })}
            </ul>
        </StyledInfo>
    );
};
