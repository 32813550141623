import classNames from 'classnames';
import React, { CSSProperties } from 'react';
import { StyledLayout } from './styles';

type Props = {
    children: React.ReactNode;
    showBackground?: boolean;
    style?: CSSProperties;
};
export const Layout = ({ children, showBackground, style }: Props) => {
    return (
        <StyledLayout style={style} className={classNames({ withBg: showBackground })}>
            <div className="content">
                <div>{children}</div>
            </div>
        </StyledLayout>
    );
};
