import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Layout } from '../../components/Layout';
import { StyledExperience } from './styles';
import { TCompany } from './types';
import { Company } from './components/Company';
import { Title } from '../../components/Title';

const companies: TCompany[] = [
    {
        isActive: true,
        position: 'Senior Frontend Developer',
        company: 'Svitla Systems, Inc.',
        logo: 'https://media.licdn.com/dms/image/C4D0BAQFx2S9-g89faA/company-logo_100_100/0/1663947249276?e=1680134400&v=beta&t=hmnC5b0yoRVElWPIeC76V3P6rqu92ppMmGTEzFS-9YE',
        period: 'oct 2022 - ongoing',
        responsibility: (
            <>
                <p>
                    Since October 2022, I have been working with Svitla Systems, Inc., a leading
                    software development company known for delivering top-quality solutions to
                    clients. In my current role, I have been actively involved in creating a new
                    website using the React JS framework.
                </p>
                <p>
                    Working with Svitla Systems has provided me the opportunity to leverage my
                    expertise in React to build a modern, high-performance website that offers an
                    exceptional user experience. I have been collaborating closely with the team to
                    ensure that the site's frontend architecture is robust, scalable, and easy to
                    maintain.
                </p>
            </>
        ),
    },
    {
        position: 'Full Stack Engineer',
        company: 'Hero Teams ',
        logo: 'https://media.licdn.com/dms/image/C560BAQEcMqnFjyj0sg/company-logo_100_100/0/1646176905126?e=1680134400&v=beta&t=8JYSsYu3UfxjcYff0Da03LElsqDMtwFmgHzEIBplFL0',
        period: 'sep 2018 - oct 2022',
        responsibility: (
            <div>
                <p>
                    During my time here, I have primarily focused on harnessing the power of React
                    to create visually appealing and highly functional frontend applications.
                </p>

                <p>
                    In addition to my expertise in React, I have also gained proficiency in writing
                    autotests using the Cypress framework. This experience has allowed me to ensure
                    the reliability and stability of the applications I develop, providing users
                    with seamless and intuitive experiences.
                </p>

                <p>
                    My tenure at the IT Company has provided me with the opportunity to work on a
                    variety of projects, constantly expanding my skill set and delivering top-notch
                    solutions to clients.
                </p>
            </div>
        ),
    },
    {
        position: 'Full-stack Developer',
        company: 'Loud & Clear',
        logo: 'https://media.licdn.com/dms/image/C560BAQEa0_4t38rAoA/company-logo_100_100/0/1543790021525?e=1680134400&v=beta&t=AvGxahS3YsIU_RtGBmvEhfeOCZOVCcrHf9louWOnk0s',
        period: 'dec 2013 - sep 2018',
        responsibility: (
            <>
                <p>
                    From December 2013 to September 2018, I held the position of a Full-stack
                    Developer at Loud & Clear. During my time there, I was responsible for a wide
                    range of tasks that showcased my versatility and expertise in web development.
                </p>

                <p>
                    One of my primary responsibilities was creating custom WordPress themes and
                    plugins, which allowed clients to have a unique and tailored digital presence.
                    My work in WordPress helped businesses establish their brand identity and create
                    a strong online foundation.
                </p>

                <p>
                    Another area of focus during my tenure at Loud & Clear was backend API
                    development using the Laravel framework. My proficiency in Laravel allowed me to
                    build robust and secure backend systems, streamlining data management and
                    ensuring seamless integration with frontend interfaces.
                </p>

                <p>
                    Overall, my time at Loud & Clear provided me with valuable experience and a
                    diverse skill set that allowed me to excel as a Full-stack Developer, delivering
                    top-notch solutions across various aspects of web development.
                </p>
            </>
        ),
    },
    {
        position: 'Full-stack Developer',
        company: 'PixelTeh',
        logo: 'https://media.licdn.com/dms/image/C560BAQEensq0eDY3aw/company-logo_100_100/0/1647326997667?e=1680134400&v=beta&t=uxqEy_veOwiaJEKBGQuXT1z7a843Q1WA700pHB-9FNk',
        period: 'jan 2013 - dec 2013',
        responsibility: (
            <>
                <p>
                    From February 2013 to December 2013, I worked as a Full-stack Developer at
                    PixelTeh, an innovative web development agency. My tenure at the company
                    provided me with a solid foundation in various aspects of web development,
                    allowing me to hone my skills and expertise.
                </p>
                <p>
                    One of my primary responsibilities at PixelTeh was creating custom WordPress
                    themes and plugins. This involved working closely with clients to understand
                    their unique requirements and delivering tailor-made solutions that helped
                    establish their online presence and brand identity.
                </p>
                <p>
                    In addition to my work with WordPress, I also gained valuable experience in
                    building websites from scratch using the CodeIgniter framework. My proficiency
                    in Codeigniter allowed me to create robust and scalable web applications that
                    catered to the specific needs of each project.
                </p>
                <p>
                    My time at PixelTeh served as a crucial stepping stone in my career as a
                    Full-stack Developer, equipping me with the diverse skill set necessary to excel
                    in the ever-evolving world of web development.
                </p>
            </>
        ),
    },
    {
        position: 'Wordpress - developer',
        company: 'BestWebSoft',
        logo: 'https://media.licdn.com/dms/image/C4E0BAQG-TNH1Hbs_hQ/company-logo_100_100/0/1646918942416?e=1680134400&v=beta&t=RESdUKRJIiBHdYVVighNLPAgURdX9L6iRYujmfbvcRw',
        period: 'june 2011 - jan 2013',
        responsibility: (
            <>
                <p>
                    From June 2011 to February 2013, I worked as a WordPress Developer at
                    BestWebSoft. This early stage of my career allowed me to develop a strong
                    foundation in WordPress development, which has since become one of my core areas
                    of expertise.
                </p>

                <p>
                    During my time at BestWebSoft, my primary responsibilities included creating
                    custom WordPress themes and plugins. This involved working closely with clients
                    to understand their specific needs, preferences, and goals. With a keen eye for
                    detail and a deep understanding of WordPress functionality, I was able to design
                    and develop unique solutions that helped businesses establish a strong online
                    presence and effectively communicate their brand values.
                </p>

                <p>
                    My experience at BestWebSoft not only allowed me to refine my skills in
                    WordPress development but also instilled in me a dedication to delivering
                    high-quality and personalized web solutions that cater to the unique
                    requirements of each client. This commitment to excellence has continued to
                    shape my approach to web development throughout my career.
                </p>
            </>
        ),
    },
];

export const Experience = () => {
    const [end, setEnd] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setEnd(true);
        }, 100);
        return () => {
            setEnd(false);
        };
    }, []);

    return (
        <Layout>
            <StyledExperience>
                <Title className={classNames({ end })}>My Experience!</Title>
                <div className={'companies'}>
                    {companies.map((comp, key) => {
                        return <Company key={key} className={'company'} {...comp} />;
                    })}
                </div>
            </StyledExperience>
        </Layout>
    );
};
