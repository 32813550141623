import styled from 'styled-components';
import bgImg from '../../assets/img/bg.jpg';
import meImg from '../../assets/img/me3.png';
import flagImg from '../../assets/img/flag.png';

export const StyledPage = styled.div`
    ${() => `background: url(${bgImg});`}
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    /*min-height: 100vh;*/
    position: relative;
    &:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        // background: rgba(0, 0, 0, 0.7);
        background: white;
    }
`;

export const StyledBio = styled.div`
    padding-top: 50px;
    display: flex;
    position: relative;
    z-index: 10;
    @media (max-width: 640px) {
        display: block;
    }
    .user {
        flex: none;
        width: 100px;
        height: 100px;
        ${() => `background: url(${meImg});`}
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 100%;
        @media (max-width: 640px) {
            margin: 0 auto;
            width: 200px;
            height: 200px;
        }
    }

    .bio {
        margin-left: 40px;
        background: rgba(0, 0, 0, 0.85);
        padding: 25px;
        padding-bottom: 50px;
        padding-top: 5px;
        flex-grow: 1;
        .contact-me {
            margin-top: 50px;
        }
        @media (max-width: 640px) {
            margin-top: 20px;
            margin-left: 0;
        }
    }
    ul {
        margin: 0;
        padding: 0;
        color: #fff;
        font-size: 38px;
        list-style: none;
        line-height: 55px;
        font-weight: bold;
        letter-spacing: 2px;
        span.br {
            color: #fd2055;
            text-shadow: #09fcd8 -3px 0px;

            /* color: #f9e13a;
            text-shadow: #2d80e3 -3px 0px;*/
        }

        .flag {
            ${() => `background: url(${flagImg});`}
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            display: inline-block;
            width: 40px;
            height: 30px;
        }
    }

    .description {
        line-height: 26px;
        letter-spacing: 0.5px;
    }
`;
