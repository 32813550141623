import React from 'react';
import classNames from 'classnames';
import { StyledMenu } from './styles';
import { useRouter } from '../SliderRouter/hooks/useRouter';

export const Menu = () => {
    const { navigate, activePageUrl } = useRouter();
    return (
        <StyledMenu>
            <li>
                <span
                    className={classNames({ active: activePageUrl === '/' })}
                    onClick={() => {
                        navigate('/');
                    }}
                >
                    About me
                </span>
            </li>
            <li>
                <span
                    className={classNames({ active: activePageUrl === '/skills' })}
                    onClick={() => {
                        navigate('/skills');
                    }}
                >
                    Skills
                </span>
            </li>
            <li>
                <span
                    className={classNames({ active: activePageUrl === '/experience' })}
                    onClick={() => {
                        navigate('/experience');
                    }}
                >
                    Experience
                </span>
            </li>
            <li>
                <span
                    className={classNames({ active: activePageUrl === '/contacts' })}
                    onClick={() => {
                        navigate('/contacts');
                    }}
                >
                    Contacts
                </span>
            </li>
        </StyledMenu>
    );
};
