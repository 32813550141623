import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Layout } from '../../components/Layout';
import { StyledContacts } from './styles';
import { Title } from '../../components/Title';
import { Form } from './components/Form';
import { Info } from './components/Info';

export const Contacts = () => {
    const [end, setEnd] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setEnd(true);
        }, 100);
    }, []);
    return (
        <Layout>
            <StyledContacts>
                <Title className={classNames({ visible: end })}>Contact Me!</Title>
                <div className="contact-columns">
                    <div className="contact-column">
                        {/* <Form /> */}

                        <Info />
                    </div>
                    <div className="contact-column">{/* <Info /> */}</div>
                </div>
            </StyledContacts>
        </Layout>
    );
};
