import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Home } from './Pages/Home';
import { Contacts } from './Pages/Contacts';
import { SliderRouter } from './components/SliderRouter';
import { Header } from './components/Header';
import { Skills } from './Pages/Skills';
import { Experience } from './Pages/Experience';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <div>
        <SliderRouter
            globalArea={<Header />}
            routes={[
                {
                    path: '/',
                    element: <Home />,
                },
                {
                    path: '/skills',
                    element: <Skills />,
                },
                {
                    path: '/experience',
                    element: <Experience />,
                },
                {
                    path: '/contacts',
                    element: <Contacts />,
                },
            ]}
        />
    </div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
