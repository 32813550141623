import styled from 'styled-components';

export const StyledExperience = styled.div`
    h1 {
        position: relative;
        left: -100px;
        top: -20px;
        transition: all 1.2s;
        &.end {
            left: 0;
            top: 0;
        }
    }

    .companies {
        display: inline-flex;
        flex-wrap: wrap;
        padding-bottom: 30px;
        gap: 25px;
        @media (max-width: 640px) {
            display: block;
        }
        .company {
            width: 45%;
            flex-grow: 1;
            @media (max-width: 640px) {
                width: 100%;
            }
        }
    }
`;
