import styled from 'styled-components';

export const StyledContainer = styled.div`
    width: 100%;

    position: relative;
    /*overflow: auto;
    overflow-y: hidden;*/
    .pages {
        height: 100vh;
        overflow: hidden;
    }
    .page {
        width: 100%;

        &.active {
            height: 100vh;
            overflow: auto;
        }
        &.prev {
            height: 100vh;
            overflow: hidden;
        }
    }
    .container {
        position: relative;
    }
`;
