import styled from 'styled-components';

export const StyledInfo = styled.div`
    padding: 20px;
    background: rgba(255, 255, 255, 0.1);
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li {
        margin-bottom: 20px;
    }

    a {
        text-decoration: none;
        color: #ffffff;
        display: flex;
        align-items: center;
        font-size: 18px;
        img {
            display: inline-block;
            margin-right: 10px;
            width: 25px;
        }
    }
`;
