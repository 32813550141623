import styled from 'styled-components';

export const StyledContacts = styled.div`
    h1 {
        @keyframes lights {
            0% {
                color: hsl(230, 40%, 80%);
                text-shadow: 0 0 1em hsla(320, 100%, 50%, 0.2),
                    0 0 0.125em hsla(320, 100%, 60%, 0.3),
                    -1em -0.125em 0.5em hsla(40, 100%, 60%, 0),
                    1em 0.125em 0.5em hsla(200, 100%, 60%, 0);
            }

            30% {
                color: hsl(230, 80%, 90%);
                text-shadow: 0 0 1em hsla(320, 100%, 50%, 0.5),
                    0 0 0.125em hsla(320, 100%, 60%, 0.5),
                    -0.5em -0.125em 0.25em hsla(40, 100%, 60%, 0.2),
                    0.5em 0.125em 0.25em hsla(200, 100%, 60%, 0.4);
            }

            40% {
                color: hsl(230, 100%, 95%);
                text-shadow: 0 0 1em hsla(320, 100%, 50%, 0.5),
                    0 0 0.125em hsla(320, 100%, 90%, 0.5),
                    -0.25em -0.125em 0.125em hsla(40, 100%, 60%, 0.2),
                    0.25em 0.125em 0.125em hsla(200, 100%, 60%, 0.4);
            }

            70% {
                color: hsl(230, 80%, 90%);
                text-shadow: 0 0 1em hsla(320, 100%, 50%, 0.5),
                    0 0 0.125em hsla(320, 100%, 60%, 0.5),
                    0.5em -0.125em 0.25em hsla(40, 100%, 60%, 0.2),
                    -0.5em 0.125em 0.25em hsla(200, 100%, 60%, 0.4);
            }

            100% {
                color: hsl(230, 40%, 80%);
                text-shadow: 0 0 1em hsla(320, 100%, 50%, 0.2),
                    0 0 0.125em hsla(320, 100%, 60%, 0.3), 1em -0.125em 0.5em hsla(40, 100%, 60%, 0),
                    -1em 0.125em 0.5em hsla(200, 100%, 60%, 0);
            }
        }
        animation: lights 5s 750ms linear infinite;
        transition-duration: 0.7s;
        transition-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
        transition-delay: 0s;
        transition-property: all;

        position: absolute;
        top: -20vh;
        opacity: 0;
        &.visible {
            position: relative;
            top: 0;
            opacity: 1;
        }
    }
    .contact-columns {
        display: flex;
        justify-content: space-between;
        @media (max-width: 640px) {
            display: block;
        }
    }
    .contact-column {
        width: 50%;
        &:first-child {
            width: 50%;
            @media (max-width: 640px) {
                width: 100%;
                margin-bottom: 20px;
            }
        }
        &:last-child {
            width: 60%;
            padding-left: 150px;
            @media (max-width: 640px) {
                width: 100%;
                margin-bottom: 20px;
            }
        }
    }
`;
