import React from 'react';
import { StyledTitle } from './styles';

type TProps = {
    children: React.ReactNode;
    className?: string;
};
export const Title = ({ children, className }: TProps) => {
    return <StyledTitle className={className}>{children}</StyledTitle>;
};
