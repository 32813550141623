import { useEffect } from 'react';

export const useScroll = (onTop: () => void, onBottom: () => void) => {
    const scrollStatus = {
        wheeling: false,
        functionCall: false,
    };
    let scrollTimer = 0;

    const callback = (e: WheelEvent) => {
        const pagesContainer: any = document.querySelector('.page.active');

        const isScrollEnd =
            pagesContainer.offsetHeight + pagesContainer.scrollTop + 1 >=
            pagesContainer.scrollHeight;

        scrollStatus.wheeling = true;
        if (!scrollStatus.functionCall) {
            if (e.deltaY > 0 && isScrollEnd) {
                onBottom();
            }
            if (e.deltaY < 0 && pagesContainer.scrollTop === 0) {
                onTop();
            }
            scrollStatus.functionCall = true;
        }
        window.clearInterval(scrollTimer);

        scrollTimer = window.setTimeout(() => {
            scrollStatus.wheeling = false;
            scrollStatus.functionCall = false;
        }, 50);
    };

    let startY = 0;
    function touchstart(e: TouchEvent) {
        startY = e.touches[0].clientY;
    }

    function touchmove(e: TouchEvent) {
        const pagesContainer: any = document.querySelector('.page.active');
        const isScrollEnd =
            pagesContainer.offsetHeight + pagesContainer.scrollTop + 1 >=
            pagesContainer.scrollHeight;

        const deltaY = e.touches[0].clientY - startY;
        if (deltaY < 0 && isScrollEnd) {
            onBottom();
        }
        if (deltaY > 0 && pagesContainer.scrollTop === 0) {
            onTop();
        }
    }

    useEffect(() => {
        document.body.addEventListener('wheel', callback);
        document.body.addEventListener('touchmove', touchmove);
        document.body.addEventListener('touchstart', touchstart);

        return () => {
            document.body.removeEventListener('wheel', callback);
            document.body.removeEventListener('touchmove', touchmove);
            document.body.removeEventListener('touchstart', touchstart);
        };
    }, []);
};
