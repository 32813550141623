import React, { useEffect, useState } from 'react';
import { StyledExperience } from './styles';

type TProps = {
    color: string;
    size: string;
};
export const Experience = ({ color, size }: TProps) => {
    const [width, setWidth] = useState('0');
    const speed = Math.random() * (1.2 - 0.3) + 0.3;

    useEffect(() => {
        setTimeout(() => {
            setWidth(size);
        }, 500);
    }, []);
    return (
        <StyledExperience>
            <div
                className="size"
                style={{ backgroundColor: color, width, transition: `all ${speed}s` }}
            />
        </StyledExperience>
    );
};
