import { useEffect, useState } from 'react';
import { SlideRoute } from '../types';

export const useNavigation = (routes: SlideRoute[]) => {
    const pageHeight = 100; // size in vh
    const timeAnimation = 600;
    const defaultPage = routes.findIndex((r) => r.path === document.location.pathname);

    const [, setIsLocked] = useState(false);

    const [activePage, setActivePage] = useState(defaultPage);
    const [activePageUrl, setActivePageUrl] = useState(routes[defaultPage].path);
    useEffect(() => {
        setActivePageUrl(routes[activePage].path);
    }, [activePage]);

    const [prevPage, setPrevPag] = useState(defaultPage);
    const [offset, setOffset] = useState(0);
    const [animationTime, setAnimationTime] = useState(timeAnimation / 1000);

    const changePath = (n: number) => {
        window.history.pushState('new Page', 'New Title', routes[n].path);
    };

    const changePage = (n: number) => {
        setIsLocked((locked) => {
            if (locked) return locked;

            if (n === activePage) {
                return false;
            }
            if (n > activePage) {
                setActivePage((state) => {
                    const newState = n;
                    setPrevPag(state);
                    setOffset(-pageHeight);
                    setTimeout(() => {
                        setAnimationTime(0);
                        setOffset(0);
                        setPrevPag(newState);
                        setTimeout(() => {
                            setAnimationTime(timeAnimation / 1000);
                            setIsLocked(false);
                        }, 100);
                    }, timeAnimation);

                    changePath(newState);
                    return newState;
                });
            } else if (n < activePage) {
                setActivePage((state) => {
                    const newState = n;
                    setAnimationTime(0);
                    setOffset(-pageHeight);
                    setPrevPag(state);
                    setTimeout(() => {
                        setAnimationTime(timeAnimation / 1000);
                        setOffset(0);
                        setTimeout(() => {
                            setPrevPag(newState);
                            setIsLocked(false);
                        }, timeAnimation);
                    }, 1);

                    changePath(newState);
                    return newState;
                });
            }

            return n === activePage;
        });
    };

    const onPrevPage = () => {
        setIsLocked((locked) => {
            if (locked) return locked;
            setActivePage((state) => {
                const newState = state - 1 > 0 ? state - 1 : 0;
                if (state !== newState) {
                    setAnimationTime(0);
                    setPrevPag(state);
                    setOffset(-pageHeight);
                    setTimeout(() => {
                        setAnimationTime(timeAnimation / 1000);
                        setOffset(0);
                        setTimeout(() => {
                            setPrevPag(newState);
                            setIsLocked(false);
                        }, timeAnimation);
                    }, 40);
                    changePath(newState);
                } else {
                    setIsLocked(false);
                }

                return newState;
            });
            return true;
        });
    };

    const onNextPage = () => {
        setIsLocked((locked) => {
            if (locked) return locked;
            setActivePage((state) => {
                const newState = state + 1 < routes.length ? state + 1 : state;

                if (state !== newState) {
                    setPrevPag(newState - 1);
                    setOffset(-pageHeight);
                    setTimeout(() => {
                        setAnimationTime(0);
                        setOffset(0);
                        setPrevPag(newState);
                        setTimeout(() => {
                            setAnimationTime(timeAnimation / 1000);
                            setIsLocked(false);
                        }, 100);
                    }, timeAnimation);
                } else {
                    setIsLocked(false);
                }

                changePath(newState);
                return newState;
            });

            return true;
        });
    };

    const navigate = (url: string) => {
        const n = routes.findIndex((r) => r.path === url);
        changePage(n);
        return url;
    };

    return {
        onPrevPage,
        onNextPage,
        changePage,
        prevPage,
        offset,
        animationTime,
        activePage,
        navigate,
        pageHeight,
        activePageUrl,
    };
};
