import React from 'react';
import classNames from 'classnames';
import { StyledContainer } from './styles';
import { Navigations } from './components/Navigations';
import { useScroll } from './hooks/useScroll';
import { useNavigation } from './hooks/useNavigation';
import { SlideRoute } from './types';

type Props = {
    routes: SlideRoute[];
    globalArea?: React.ReactNode;
};

const RouterContext = React.createContext({
    navigate: (str: string) => str,
    activePageUrl: '',
});

const SliderRouter = ({ routes, globalArea }: Props) => {
    const {
        onPrevPage,
        onNextPage,
        changePage,
        prevPage,
        offset,
        animationTime,
        activePage,
        navigate,
        activePageUrl,
    } = useNavigation(routes);
    useScroll(onPrevPage, onNextPage);

    return (
        <StyledContainer>
            <RouterContext.Provider
                value={{
                    navigate,
                    activePageUrl,
                }}
            >
                {globalArea}
                <div className="pages">
                    <div
                        className="container"
                        style={{
                            top: `${offset}vh`,
                            transition: `all ${animationTime}s`,
                        }}
                    >
                        {routes.map((route, key) => {
                            return (
                                <div
                                    key={route.path}
                                    className={classNames({
                                        page: true,
                                        active: activePage === key,
                                        prev: prevPage === key && prevPage !== activePage,
                                    })}
                                >
                                    {activePage === key || prevPage === key ? route.element : ''}
                                </div>
                            );
                        })}
                    </div>
                </div>
                <Navigations active={activePage} count={routes.length} onClick={changePage} />
            </RouterContext.Provider>
        </StyledContainer>
    );
};

export { RouterContext, SliderRouter };
